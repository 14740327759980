@BASE_PATH: '';
// 数据模块
.dataSectionRightClass{
  border-left: 1px solid #2f2f2f;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

}

.borderClass{
  border: 1px solid #2f2f2f; //#2f2f2f
  border-radius: 18px;
  
}

@media screen and (max-width: 766px){
  .dataSectionRightClass{
    border-top: 1px solid #2f2f2f; 
    border-left: none;
  }
}

.dataTipClass{
  text-align: right;
  margin-top: 24px;
}

