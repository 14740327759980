@BASE_PATH: '';
.socialwrapper{
  display: flex;
  align-items: center;
  svg{
    margin-right: 8px;
  }

}

.socialwrapper:hover{
  .socialBase{
    fill: #26f2d1;
  }
}

// 991-479 图片不展示
@media screen and (max-width: 991px) and (min-width: 479px){
  .gridWrapper{
    grid-template-columns:0 auto auto auto;
  }
}

.content{
  padding-top: 32px;
}

.btn:hover{
  color: #26f2d1 !important;
  background-color: #060606 !important;
  border-color: #060606 !important;
}
