@BASE_PATH: '';

// 走马灯
.partnerClass {
 overflow: hidden; //多余的隐藏
 position: relative;
 width: 100vw; //100% 屏幕下展示7个
 height: 56px;
}

.imgWrapperClass{
  position: absolute;
  top: 0;
  left: 0;
  width: calc(300vw);  //盒子的总宽度，一共有21=14+7, 7个100vw  那么 21个300vw
  height: 56px; // 
  animation: partnerAnimation 40s  infinite linear ;
  img{
    width: calc(100vw/7 - 4vw); 
    margin: 0 2vw;
    height: 56px;  
  }
 }

 // 视口展示6个
@keyframes partnerAnimation {
  0%{
   transform: translateX(0vw);
  } 
  100%{
    transform: translateX(-200vw); //需要展示14个，7个100vw  那么14个200vw
  }
}



//  ipad及手机屏幕展示3个
@media screen and (max-width: 768px){
  @keyframes partnerAnimation {
    0%{
     transform: translateX(0vw);
    } 
    100%{
    transform: translateX(calc(-466.7vw )); //需要展示14个，3个100vw，14个466.7vw
    }
  }

  .partnerClass {
    overflow: hidden;
    position: relative;
    width: 100vw; //3个100% 
    height: 28px;
   }

  .imgWrapperClass{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(700vw);  //一共展示的为21个， 3个100vw  21个700vw
    height: 28px; // 
    animation: partnerAnimation 40s  infinite linear ;
    img{
      width: calc(100vw/3 - 8vw); 
      margin: 0 4vw;
      height: 28px;  
    }
   }
}

