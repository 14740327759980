@BASE_PATH: '';
.downIcons{
  display: flex;
  img{
    width: 52px;
    height: 52px;
  }

  // pc
  .icon,.darkIcon,.iconTestFlight,.darkIconTestFlight{
    margin-right: 32px;
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    .icon,.darkIcon,.iconTestFlight,.darkIconTestFlight{
      margin-right: 24px;
    }
  }
  // icon 分四种类型
  .icon{
    svg{
      path{
        fill: white;
      }
  
      rect{
        stroke: white;
      }
     
    }
  }
  .iconTestFlight{
    svg{
      path:nth-child(odd){
        fill: white;
      }
  
      rect{
        stroke: white;
      }
     
    }
  }
  .darkIconTestFlight{
    svg{
      path{
        fill: #060606;
      }
      path:nth-child(3){
        fill: white;
      }
     
      rect{
        stroke: #060606;
      }
       
    }

  }

  .darkIcon{
    svg{
      path{
        fill: #060606;
      }
  
      rect{
        stroke: #060606;
      }
    }
  }
  // hover只有两种
 .icon:hover{
    svg{
      path{
        fill: #26f2d1;
      }
  
      rect{
        stroke: #26f2d1;
      }
    }
  }

  .iconTestFlight:hover{
    svg{
      path:nth-child(1){
        fill: #26f2d1;
      }
  
      rect{
        stroke: #26f2d1;
      }
     
    }
  }

  .icon:last-child{
    margin-right: 0;
  }
  

}
.tooltipQrcode{
  background: #2D2D2D;
  border-radius: 8px;
  :global{
    .ant-tooltip-arrow:before{
      background:#2D2D2D;
    }
    
    .ant-tooltip-content{
      background: #2D2D2D;
      padding: 16px;
      border-radius: 8px;

    } 
    .ant-tooltip-inner{
      background: #2D2D2D; 
      padding: 0;
      width: 134px;
    }

  }
  .text{
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    font-family: Roboto;
    margin-bottom: 16px;
  } 
  img{
    width: 134px;
    height: 134px;
  }
}