@BASE_PATH: '';

@media screen and (min-width: 1440px) {
  .roadmap{
    padding-bottom: 120px !important;
  }
}

.pl20{
  padding-left: 20px;
  li{
    line-height:28px;
    margin-bottom: 0px;
    color: rgba(192, 192, 192, 1);
    font-weight: 300;
   span{
    color: #fff;
    font-weight: 600;
   }
  }
}

.wSlide{
  min-width: 420px;
  white-space: normal;
  text-align: left;
}

