@BASE_PATH: '';

.homeBanner{
  position: relative;
}
@media screen and (max-width: 479px){
  .homeBanner{
    padding-top: 160px !important;
  }
}

.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    margin-right: 6px;
  }
}

