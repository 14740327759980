@BASE_PATH: '';

@media screen and (max-width: 991px) and (min-width: 767px) {
  .topImg{
    width: 50% !important;
    margin-left: 20% !important;
  }
  .bottomImg{
    width: 60% !important;
    left: 25% !important;
  }
}




