@BASE_PATH: '';
// 数据模块
.btnLink{
  color:rgba(38, 242, 209, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span{
    margin-right: 4px;
  }
}
.btnLink:hover{
  a{
    color:rgba(38, 242, 209, 1);
  }
}

.lineHeight28{
  line-height: 28px;
}


