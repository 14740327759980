@BASE_PATH: '';
.announcement{
  position: absolute;
  top: 120px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto; 
}

// 991-479 图片不展示
@media screen and (max-width: 479px){
  .announcement{

    top: 100px;
   
  }
}

.sliderWrapper{
  overflow:hidden;
  position:relative;
}

// 保证居中展示
.itemCenter{
  cursor: pointer;
  height: 58px;
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 保证宽度是内容的宽度
.itemWidth{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper{
  margin: 8px 0;
  background-color: #26f2d1;
  padding: 8px 24px;
  border: 1px solid #26f2d1;
  border-radius: 24px;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 280px;
  .trumpetIcon{
    margin-right: 8px;
  }
  .itemText{
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    margin-right: 24px;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .itemSvg{
    position: absolute;
    right: 24px;
    
  }
}

@media screen and (min-width: 479px){
  .itemWrapper{
    max-width: 50vw;
  }
  // .itemText{
  //  white-space: nowrap; //溢出不换行
  // } 
}

// h5
@media screen and (max-width: 479px){
  .itemWrapper{
    margin: 8px 16px;
  }
  // .itemText{
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2; 
  //   /*! autoprefixer: off */
  //   -webkit-box-orient: vertical;
  // }

}











