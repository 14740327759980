@BASE_PATH: '';



.feedBack{
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  a{
    display: flex;
    align-items: center;
    padding:12px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .backText{
    margin-left:10px;
  }
}

// H5  feedBack
@media screen and (max-width: 991px){ 
  .feedBack{
    a{
     padding: 16px;
     border-radius: 30px;
     }
     .backText{
       display: none
     }  
   }
 }

// homeSocials
 .homeSocialIcon:hover{
  fill: #26f2d1;
}

.homeSocials{
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 100;
  background: rgba(6, 6, 6, 0.5);
  border-radius: 100px;
  border: 1px solid #3A3A3A;
  backdrop-filter: blur(10px);
  padding: 16px 24px 10px 24px;
  .homeSocialItem{
    margin-left: 16px;
  }
  .homeSocialItem:first-child{
    margin-left: 0;
  }
}


@media screen and (max-width: 768px){ 
  .homeSocials{
     display: none;
  }
 }

