@BASE_PATH: '';

.tabItem:hover{
  h3{
    color:#26f2d1 !important;
  }
}

.bodyNoActive{
  display: none;
}
.iconNoActive{
  align-self: center;
}

.lineHeight28{
  line-height: 28px;
}



.mb48{
  margin-bottom: 48px !important;
}

@media screen and (min-width: 991px){
  .py160{
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
}

@media screen and (min-width: 479px){
  .h3{
    width: 349px
  }
}

@media screen and (max-width: 479px){
  .tabNoActive{
    align-items: flex-start;
  }

}

@media screen and (max-width: 991px){
  .rightImg{
    display: none;
  }
}




